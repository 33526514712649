<template>
  <div class="pump-video ly-wrap">
    <div class="currency-title" style="background-color: #ffffff;"><span class="back" @click="goBack()"></span>视频监控
    </div>
    <div class="ly-nav-bar">
      <div :class="[isType==='voice'?'on':'','nav-item']" @click="handleType('voice')">对讲模式</div>
      <div :class="[isType==='security'?'on':'','nav-item']" @click="handleType('security')">回放模式</div>
    </div>
    <div class="video-wrap" style="width: 100%;height: 8.5rem;">
      <EZUIKitJs :setObj="setObj"/>
    </div>
    <div class="tab-wrap">
      <div class="tab-item" @click="handleUrl('ezopen://open.ys7.com/E46528190/1.hd.live')">
        <div class="btn ing" v-if="isUrl == 'ezopen://open.ys7.com/E46528190/1.hd.live'">正在播放</div>
        <div class="btn" v-else>播放</div>
        <div class="name">办公室</div>
      </div>
      <div class="tab-item" @click="handleUrl('ezopen://open.ys7.com/F45489586/1.hd.live')">
        <div class="btn ing" v-if="isUrl == 'ezopen://open.ys7.com/F45489586/1.hd.live'">正在播放</div>
        <div class="btn" v-else>播放</div>
        <div class="name">大门</div>
      </div>
    </div>

    <div class="back-btn" @click="handleRefresh">
      <div class="icon"></div>
      刷新页面
    </div>

  </div>
</template>

<script>

import EZUIKitJs from '../../components/EZUIKitJs.vue'

export default {
  name: 'Video',
  components: {EZUIKitJs},
  computed: {},
  data() {
    return {
      isType: 'voice',
      isUrl: 'ezopen://open.ys7.com/E46528190/1.hd.live',
      setObj: {
        type: 'voice',
        url: 'ezopen://open.ys7.com/E46528190/1.hd.live',
      },
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    handleUrl(url) {
      this.isUrl = url
      this.setObj.url = url
    },
    handleType(type) {
      this.isType = type
      this.setObj.type = type
    },
    handleRefresh() {
      this.$router.go(0)
    },
    goBack() {
      this.$router.go(-1)
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">

.pump-video {
  width: 100%;
  height: 100%;
  background-color: #4D4D4DFF;
}

.ly-nav-bar {
  width: 100%;
  height: 0.8rem;
  background-color: #4D4D4DFF;
  display: flex;

  .nav-item {
    flex: 1;
    height: 0.72rem;
    line-height: 0.72rem;
    text-align: center;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 0.3rem;
    background-color: #333333;
  }

  .on {
    background-color: #4D4D4DFF;
  }
}

.video-wrap {
  background-color: #3D3D3DFF;
}

.tab-wrap {
  width: 100%;
  min-height: 1.94rem;
  padding-top: 0.2rem;

  .tab-item {
    width: 1.58rem;
    height: 1.58rem;
    background: url("~@/assets/img/vidoe-bg.png") no-repeat center;
    background-size: 1.58rem;
    float: left;
    margin-left: 0.235rem;
    margin-bottom: 0.3rem;
    color: #FFFFFF;
    text-align: center;

    .btn {
      width: 0.8rem;
      height: 0.4rem;
      margin: 0.3rem auto 0.2rem;
      line-height: 0.4rem;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
    }

    .ing {
      width: 100%;
      border: none;
    }

    .name {
      font-size: 0.28rem;
    }
  }
}

.back-btn {
  width: 7rem;
  margin: 0 auto;
  height: 0.88rem;
  background: #4D4D4D;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.36rem;
  line-height: 0.88rem;

  .icon {
    width: 0.43rem;
    height: 0.43rem;
    background: url("~@/assets/img/refresh.png") no-repeat center;
    background-size: 0.42rem;
    position: relative;
    top: 0.08rem;
    display: inline-block;
  }
}

@import '~@/assets/less/view.less';
</style>

